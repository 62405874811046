import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import BG from "./bg.webp";
import BG3 from "./bg3.webp";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import AssessmentIcon from "@mui/icons-material/Assessment";

function Landing() {
  return (
    <div>
      <Box textAlign="center" sx={{ backdropFilter: "blur(5px)" }} py={4} borderRadius={5}>
        <Typography variant="h4" component="h1" gutterBottom>
          PUMPILIO
        </Typography>
        <Typography>CA - 3YijSA2VCJmDY5VcxUws9vNJnG8JKg8FH37nH3bEhMEQ</Typography>
        <Typography>
          <a href="https://pump.fun/3YijSA2VCJmDY5VcxUws9vNJnG8JKg8FH37nH3bEhMEQ" target="_blank">
            [PUMP FUN]
          </a>
        </Typography>
      </Box>
      <img src={BG3} width={"100%"} alt="Pumpilio" />
      <Grid container spacing={4} p={4}>
        <Grid item xs={12} sm={6}>
          <a
            href="https://t.me/pumpiliocallio"
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Box
              border={1}
              justifyContent={"left"}
              sx={{
                height: "100%",
                textAlign: "left",
                boxShadow:
                  "rgba(223, 215, 189, 0.4) 5px 5px, rgba(223, 215, 189, 0.3) 10px 10px, rgba(223, 215, 189, 0.2) 15px 15px",
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#141414")} // Change background color on hover
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#000")} // Revert back to original background color on hover out
            >
              <Box display={"flex"} alignItems={"center"} gap={1} bgcolor={"#141414"} py={2} px={4}>
                <Typography>
                  <RecordVoiceOverIcon />
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  CALLIO
                </Typography>
              </Box>

              <Box px={4} py={2} pb={6}>
                <Typography variant="body1">
                  Algorithm to analyse pump.fun tokens and call tokens that are hyped. Time to make
                  money retardio.
                </Typography>
              </Box>
            </Box>
          </a>
        </Grid>
        <Grid item xs={12} sm={6}>
          <a
            href="https://t.me/pumpiliobot"
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Box
              border={1}
              sx={{
                height: "100%",
                textAlign: "left",
                boxShadow:
                  "rgba(223, 215, 189, 0.4) 5px 5px, rgba(223, 215, 189, 0.3) 10px 10px, rgba(223, 215, 189, 0.2) 15px 15px",
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#141414")} // Change background color on hover
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#000")} // Revert back to original background color on hover out
            >
              <Box display={"flex"} alignItems={"center"} gap={1} bgcolor={"#141414"} py={2} px={4}>
                <Typography>
                  <SmartToyIcon />
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  INFOLIO
                </Typography>
              </Box>
              <Box px={4} py={2} pb={6}>
                <Typography variant="body1">
                  Telegram bot you can add to any group and send pump.fun links or CA and get
                  information about the token.
                </Typography>
              </Box>
            </Box>
          </a>
        </Grid>
      </Grid>
      <Box
        textAlign={"center"}
        gap={2}
        p={5}
        display={"flex"}
        alignContent={"center"}
        justifyContent={"center"}
      >
        <a
          href="https://twitter.com/pumpilio"
          target="_blank"
          style={{ textDecoration: "none", color: "white" }}
        >
          <XIcon fontSize="large" />
        </a>
        <a
          href="https://t.me/pumpilio"
          target="_blank"
          style={{ textDecoration: "none", color: "white" }}
        >
          <TelegramIcon fontSize="large" />
        </a>
        <a
          href="https://dexscreener.com"
          target="_blank"
          style={{ textDecoration: "none", color: "white" }}
        >
          <AssessmentIcon fontSize="large" />
        </a>
      </Box>
    </div>
  );
}

export default Landing;
