import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Landing from "./components/Landing";
import BG from "./components/bg.webp";
import { createTheme, ThemeProvider } from "@mui/material";
const theme = createTheme({
  typography: {
    fontFamily: "Jersey 10",
    fontSize: "20",
  },
});

const styles = {
  root: {
    backgroundColor: `black`, // Replace with your image path
    backgroundSize: "cover",
    fontFamily: "Jersey 10", // Use the imported font
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    color: "white",
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div style={styles.root}>
        <Box width={"100%"}>
          <Landing />
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
